/* ===========================
Component - Category Cover
=========================== */

.c-category-cover {
	padding: rem(16) 0 rem(24) 0;
	background-color: rgba(var(--taupe-050), 1);
	background-size: cover;
	background-position: center;

	@include breakpoint(min-768) {
		padding-bottom: rem(36);
	}

	@include breakpoint(min-1024) {
		padding-bottom: rem(48);
	}
}

.c-category-cover__header {
    color: var(--gray-90);
    font-size: var(--font-size-8);
    font-family: $font-stack-display;
    line-height: rem(32);
    font-weight: var(--font-weight-regular);
    text-align: center;
    max-width: 36ch;
    display: block;
    margin: 0 auto rem(12) auto;

    @include breakpoint(min-768) {
        font-size: var(--font-size-9);
        line-height: rem(36);
    }

    @include breakpoint(min-1024) {
        font-size: var(--font-size-10);
        line-height: rem(48);
        margin-bottom: rem(24);
    }
}

.c-category-cover__desc {
    width: 92%;
    max-width: 60ch;
    text-align: center;
    margin: 0 auto rem(24) auto;
    display: block;

    @include breakpoint(min-768) {
        margin: 0 auto rem(44) auto;
        -webkit-line-clamp: 2;
    }

    @include breakpoint(min-1024) {
        margin: 0 auto rem(52) auto;
    }
}

.c-category-cover__desc--truncated {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: rem(96);
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  

    @include breakpoint(min-768) {
        -webkit-line-clamp: 2;
        max-height: rem(48);
    }
}