/* ===========================
Product config intro
=========================== */

.collection-cover.config-intro-header {
    background-image: url('../../../dist/images/products-config/config-cover-mobile.jpg');
    @include image-2x('../../../dist/images/products-config/config-cover-mobile@2x.webp', cover);
    height: rem(300);
    padding: rem(48) rem(16) rem(40);

    @include breakpoint(min-768) {
        background-image: url('../../../dist/images/products-config/config-cover-tablet.jpg');
        @include image-2x('../../../dist/images/products-config/config-cover-tablet@2x.webp', cover);
        height: rem(360);
        padding: rem(60) rem(20) rem(52);
    }

    @include breakpoint(min-1024) {
        background-image: url('../../../dist/images/products-config/config-cover.jpg');
        @include image-2x('../../../dist/images/products-config/config-cover@2x.webp', cover);
        height: rem(400);
        padding: rem(80) rem(32);
    }

    h1, p {
        color: var(--pink-70, #7A3040);
    }

    small {
        font-family: $font-stack-text;
        display: block;
        font-size: var(--font-size-3);
        letter-spacing: rem(3);
        line-height: rem(40);
        text-transform: uppercase;
        color: var(--pink-50, #BD7B86);
    }
}