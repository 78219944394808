/* ===========================
List filter
=========================== */

// Layout
.l-filter {
	padding: var(--space-3) var(--space-0) var(--space-4) var(--space-0);
	background: var(--white);
	display: flex;
	flex-direction: column;
}

// Layout first row (filters, desktop sort filter)
.l-filter__first-row {
	display: flex;
	padding-bottom: var(--space-3);
	border-bottom: rem(1) solid var(--gray-10);
}

// Layout first row (selected filters, search result, mobile sort filter)
.l-filter__second-row {
	display: flex;
	justify-content: space-between;
	padding: var(--space-3) var(--space-0) var(--space-4) var(--space-0);
	gap: var(--space-4);
}

// Fade for filter component
.l-filter__fade {
	position: relative;
	width: 0;
	z-index: 1;

	&::before {
		content: "";
		position: absolute;
		width: rem(25);
		height: rem(44);
		left: rem(-10);
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 1) 100%
		);
	}

	+ .c-filter__item {
		z-index: 2;
	}
}


// Search result label
.l-filter__search-result {
	display: inline-flex;
	align-items: center;
	gap: var(--space-1);
	flex: 0 0 auto;
	color: var(--gray-70);
	font-size: var(--font-size-2);
	line-height: var(--line-height-3);

	@include breakpoint(min-768) {
		align-items: flex-start;
		margin-left: auto;
	}
}

.l-filter__search-result--mobile {
	@include breakpoint(min-768) {
		display: none;
	}
}

.l-filter__search-result .strong {
	color: var(--gray-80);
	font-weight: var(--font-weight-semibold);

	@include breakpoint(min-768) {
		margin-left: var(--space-4);
	}
}

// 1. Filter component
.c-filter {
	margin: var(--space-0);
	padding: var(--space-0);
	list-style-type: none;
	display: inline-flex;
	gap: var(--space-2);
	overflow-x: auto;
	// position: static;
}

.c-filter__item-wrapper {
	flex: 0 0 auto;
	position: static;

	&:last-child {
		order: -1;
	}

	//position: static;

	@include breakpoint(min-640) {
		// position: relative;
	}
}

.c-filter__item {
	display: inline-flex;
	flex: 0 0 auto;
	align-items: center;
	gap: var(--space-1);
	border: none;
	height: rem(36);
	padding: var(--space-1) var(--space-3);
	margin: var(--space-1) var(--space-0);
	background-color: var(--gray-10);
	border-radius: var(--border-radius-5);
	color: var(--gray-80);
	font-size: var(--font-size-2);
	line-height: var(--line-height-2);
	font-weight: var(--font-weight-semibold);
	transition: box-shadow 0.2s $ease-in-out-quad,
		background-color 0.2s $ease-in-out-quad;

	&:hover {
		background-color: var(--gray-20);

		.icon {
			fill: var(--gray-80);
		}
	}

	&:focus,
	&:focus-visible {
		outline: none;
		box-shadow: inset 0 0 0 rem(1) var(--gray-60),
			0 0 0 rem(3) var(--gray-20);
	}
}

.c-filter__item-counter {
	border-radius: var(--border-radius-3);
	background-color: var(--gray-80);
	padding: var(--space-0) var(--space-1);
	min-width: rem(20);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: var(--white);
}

// 2. Sort filter component
.c-sort-filter {
	position: relative;
	margin-left: auto;
	flex: 0 0 auto;
	display: inline-flex;
	align-items: center;
	gap: var(--space-1);
	font-size: var(--font-size-3);
	line-height: var(--line-height-3);
}

.c-sort-filter--mobile {
	@include breakpoint(min-768) {
		display: none;
	}
}

.c-sort-filter--desktop {
	display: none;

	@include breakpoint(min-768) {
		display: inline-flex;
	}
}

.c-sort-filter__label {
	color: var(--gray-70);
	display: none;

	@include breakpoint(min-480) {
		display: block;
		margin-left: var(--space-6);
	}
}

.c-sort-filter__button {
	position: relative;
	display: inline-flex;
	align-items: center;
	color: var(--gray-80);
	border-radius: var(--border-radius-1);
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-2);
	padding: var(--space-1) var(--space-2);
	gap: var(--space-2);
	border: none;
	height: rem(32);
	background-color: transparent;
	text-decoration: none;
	transition: background-color 0.2s $ease-in-out-quad,
		box-shadow 0.2s $ease-in-out-quad;

	&:hover {
		background-color: var(--gray-10);
	}

	&:focus,
	&:focus-visible {
		outline: none;
		box-shadow: inset 0 0 0 rem(1) var(--gray-60),
			0 0 0 rem(3) var(--gray-20);
	}
}

.c-sort-filter__dropdown {
	position: absolute;
	z-index: $z-index-5;
	background-color: var(--white);
	list-style-type: none;
	margin: var(--space-0);
	padding: var(--space-0);
	right: 0;
	top: calc(100% + #{rem(4)});
	border-radius: var(--border-radius-1);
	box-shadow: var(--box-shadow-small);
	min-width: rem(200);
}

.c-sort-filter__dropdown-wrapper {
	border-bottom: rem(1) solid var(--gray-10);

	&:last-child {
		border-bottom: none;
	}
}

.c-sort-filter__dropdown-item {
	color: var(--gray-80);
	font-size: var(--font-size-3);
	line-height: var(--line-height-3);
	font-weight: var(--font-weight-semibold);
	display: flex;
	text-decoration: none;
	padding: var(--space-2) var(--space-3);
	transition: background-color 0.2s $ease-in-out-quad;

	&:hover {
		background-color: var(--gray-10);
	}
}

// 3. Selected filter component
.c-selected-filter {
	display: none;

	@include breakpoint(min-768) {
		display: inline-flex;
		margin: var(--space-0);
		padding: var(--space-0);
		list-style-type: none;
		flex-wrap: wrap;
		gap: var(--space-1);
	}
}

.c-selected-filter__wrapper {
	flex: 0 0 auto;
}

.c-selected-filter__item {
	position: relative;
	display: inline-flex;
	flex: 0 0 auto;
	align-items: center;
	gap: var(--space-2);
	border: rem(1) solid var(--gray-10);
	height: rem(28);
	padding: var(--space-05) var(--space-2);
	background-color: var(--white);
	border-radius: var(--border-radius-5);
	color: var(--gray-80);
	font-size: var(--font-size-2);
	line-height: var(--line-height-2);
	font-weight: var(--font-weight-semibold);
	text-decoration: none;
	transition: box-shadow 0.2s $ease-in-out-quad,
		border-color 0.2s $ease-in-out-quad;

	&:hover {
		border-color: var(--gray-30);

		.icon {
			fill: var(--gray-80);
		}
	}

	&:focus,
	&:focus-visible {
		outline: none;
		box-shadow: inset 0 0 0 rem(1) var(--gray-60),
			0 0 0 rem(3) var(--gray-20);
	}
}

.c-selected-filter__item--cancel {
	text-decoration: underline;
	border: none;
}

// 4. Filter dropdown
.c-filter-dropdown {
	z-index: $z-index-9;
	position: absolute;
	// position: fixed;
	display: flex;
	overflow-y: scroll;
	// left: 0;
	width: 100%;
	max-width: 300px;
	padding: var(--space-4);
	background-color: var(--white);
	box-shadow: var(--box-shadow-small);
	flex-direction: column;
	gap: var(--space-2);
	margin: var(--space-0);
	border-radius: var(--border-radius-1);
	// min-width: rem(320);
	// min-width: 100%;
	// max-height: 50vh;
	// top: 0;
	// top: 40px;
	// background-color: red;
	// background: beige;

	@include breakpoint(min-640) {
		// top: 0;
		// bottom: auto;
	}
}


.c-filter-dropdown#barva_hlavniho_kamene:not(.c-filter-dropdown--fullscreen-modal) {
	max-width: 600px;
}

.c-filter-dropdown#barva_hlavniho_kamene:not(.c-filter-dropdown--fullscreen-modal) .c-filter-dropdown__list {
	display: flex;
	flex-wrap: wrap;
}

.c-filter-dropdown#barva_hlavniho_kamene:not(.c-filter-dropdown--fullscreen-modal) .c-products-filter__item {
	width: 50%;
	flex: 1 1 50%;
}

.c-filter-dropdown[hidden] {
	display: none;
}

// Modification for fullscreen filter modal
.c-filter-dropdown--fullscreen-modal {
	box-shadow: none;
	max-width: none;
	position: static;
	overflow-y: unset;
	max-height: max-content;

	&:first-child {
		.c-filter-dropdown__list {
			padding: 0;
		}
	}

	@include breakpoint(min-640) {
		padding: var(--space-6);
	}
}

.c-filter-dropdown--fullscreen-modal .c-filter-dropdown__heading {
	display: inline-flex;
	flex-wrap: wrap;
	align-items: center;
	border: none;
	background-color: var(--white);
}
// Modification for fullscreen filter modal END

.c-filter-dropdown__heading {
	font-size: var(--font-size-4);
	line-height: var(--line-height-3);
	font-weight: var(--font-weight-semibold);
	color: var(--gray-80);
}

// For fullsceen filted modal only
.c-filter-dropdown__heading-filtered-items {
	width: 100%;
	color: var(--gray-50);
	font-size: var(--font-size-3);
	line-height: var(--line-height-3);
	font-weight: var(--font-weight-regular);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: left;
}

.c-filter-dropdown__list {
	margin: var(--space-0);
	padding: var(--space-4) var(--space-0) var(--space-2) var(--space-0);
	list-style-type: none;
}

.c-filter-dropdown__item {
	//TODO: waitign for new checkbox component
}

.c-filter-dropdown__cancel {
	display: inline-flex;
}

.c-filter-dropdown__cancel a {
	font-size: var(--font-size-2);
	line-height: var(--line-height-2);
	font-weight: var(--font-weight-regular);
}

.c-filter-dropdown__close {
	position: absolute;
	right: rem(16);
	top: rem(16);
	border-radius: var(--border-radius-half);
	background-color: var(--gray-10);
	width: rem(28);
	height: rem(28);
	border: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: box-shadow 0.2s $ease-in-out-quad,
		background-color 0.2s $ease-in-out-quad;

	&:hover {
		background-color: var(--gray-20);

		.icon {
			fill: var(--gray-60);
		}
	}

	&:focus,
	&:focus-visible {
		outline: none;
		box-shadow: inset 0 0 0 rem(1) var(--gray-60),
			0 0 0 rem(3) var(--gray-20);
	}
}

.c-filter-dropdown__close .icon {
	flex: 0 0 auto;
	fill: var(--gray-50);
	width: rem(12);
	height: rem(12);
	transition: fill 0.2s $ease-in-out-quad;
}

// 5. Filter fullscreen
.c-filter-fullscreen-modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $z-index-12;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	background-color: rgba(28, 27, 26, 0.7);
}

.c-filter-fullscreen-modal.hidden {
	clip: rect(0 0 0 0);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

.c-filter-fullscreen-modal.hidden .c-filter-fullscreen-modal__wrapper {
	transform: translateY(100%);

	@include breakpoint(min-640) {
		transform: translateY(0);
		transform: translateX(100%);
	}
}

.c-filter-fullscreen-modal__wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
	transform: translateX(0), translateY(0);
	transition: transform 0.2s $ease-in-out-quad;

	@include breakpoint(min-640) {
		flex-direction: row;
		margin-left: auto;
		align-items: flex-start;
	}
}

.c-filter-fullscreen-modal__close-wrapper {
	display: flex;
	justify-content: flex-end;
	margin: var(--space-4);
}

.c-filter-fullscreen-modal__close {
	flex: 0 0 auto;
	border-radius: var(--border-radius-half);
	background-color: var(--white);
	width: rem(36);
	height: rem(36);
	border: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: box-shadow 0.2s $ease-in-out-quad,
		background-color 0.2s $ease-in-out-quad;

	&:hover {
		background-color: var(--gray-20);

		.icon {
			fill: var(--gray-60);
		}
	}

	&:focus,
	&:focus-visible {
		outline: none;
		box-shadow: inset 0 0 0 rem(1) var(--gray-60),
			0 0 0 rem(3) var(--gray-20);
	}
}

.c-filter-fullscreen-modal__content {
	border-radius: var(--border-radius-3) var(--border-radius-3) 0 0;
	background-color: var(--white);
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: calc(100% - #{rem(68)});
	box-shadow: var(--box-shadow-small);

	@include breakpoint(min-640) {
		border-radius: 0;
		max-width: rem(360);
		height: 100%;
		max-height: none;
	}
}

.c-filter-fullscreen-modal__header-wrapper {
	border-radius: var(--border-radius-3) var(--border-radius-3) 0 0;
	display: flex;
	align-items: center;
	padding: var(--space-4);
	gap: var(--space-4);
	border-bottom: rem(1) solid var(--gray-20);
	background-color: var(--white);

	@include breakpoint(min-640) {
		border-radius: 0;
		padding: var(--space-6);
	}
}

.c-filter-fullscreen-modal__header-label {
	font-size: var(--font-size-5);
	line-height: var(--line-height-4);
	font-weight: var(--font-weight-semibold);
}

.c-filter-fullscreen-modal__header-filter-cancel {
	margin-left: auto;
	font-size: var(--font-size-2);
	line-height: var(--line-height-2);
	font-weight: var(--font-weight-regular);
}

.c-filter-fullscreen-modal__filters-wrapper {
	height: 100%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: var(--space-05);
	background-color: var(--gray-10);

	@include breakpoint(min-640) {
		gap: var(--space-0);
		background-color: var(--white);
	}
}

.c-filter-fullscreen-modal__footer-wrapper {
	display: flex;
	align-items: center;
	padding: var(--space-4);
	gap: var(--space-4);
	border-top: rem(1) solid var(--gray-20);
	background-color: var(--white);

	@include breakpoint(min-640) {
		padding: var(--space-6);
	}
}

// Filter icons
.c-filter__item .icon,
.c-sort-filter__button .icon,
.c-selected-filter__item .icon,
.c-filter-fullscreen-modal__close .icon,
.c-filter-dropdown__heading .icon {
	fill: var(--gray-70);
	flex: 0 0 auto;
	transition: fill 0.2s $ease-in-out-quad;
}

.c-filter__item .icon-chevron-bottom-outline {
	width: rem(8);
	height: rem(8);
	margin-left: var(--space-1);
}

.c-filter__item .icon-filter {
	width: rem(16);
	height: rem(16);

	margin-left: var(--space-1);
}

.c-sort-filter__button .icon,
.c-selected-filter__item .icon {
	width: rem(8);
	height: rem(8);
}

.c-filter-fullscreen-modal__close .icon {
	width: rem(14);
	height: rem(14);
}

.c-filter-dropdown__heading .icon {
	width: rem(8);
	height: rem(8);
	margin-left: auto;
}

.c-products-filter {
	padding: rem(8);
	background: var(--white);
	z-index: $z-index-9;

	@include breakpoint(min-768) {
		padding: rem(16);
	}
}

.c-products-filter__container {
	display: none;
	width: 100%;
	position: relative;

	@include breakpoint(min-1024) {
		display: flex;
		flex-direction: column;
	}
}

.c-products-filter__header {
	padding: rem(20) rem(16);
	margin: 0;
	font-size: var(--font-size-7);
}

.c-products-filter__container--visible {
	display: block;
}

.c-products-filter__list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.c-products-filter__list--checkboxes .c-products-filter__item {
	@include breakpoint(min-768) {
		flex: 0 0 auto;
		width: 100%;
	}

	@include breakpoint(min-1024) {
		width: auto;
		flex: 0 1 auto;
	}
}

.c-products-filter__item {
	width: 100%;
	flex: 1 1 100%;
	transition: background-color 0.2s $ease-in-out-quad;
}

.c-products-filter__item:hover {
	background-color: var(--gray-10);
}

.c-products-filter__item--selected {
	box-shadow: 0 rem(1) 0 0 var(--gray-80);
}

.c-products-filter__item-name {
	text-transform: uppercase;
	color: var(--gray-80);
	font-size: var(--font-size-2);
	line-height: rem(20);
	width: 100%;
	padding: rem(20) rem(32) rem(20) rem(16);
	position: relative;
	border: none;
	text-align: left;
	border-bottom: rem(1) solid var(--gray-20);
	font-weight: var(--font-weight-semibold);
	background-color: var(--white);

	@include breakpoint(min-768) {
		width: 100%;
		border-bottom: none;
	}
}

.c-products-filter__item-name .icon-chevron-bottom {
	position: absolute;
	width: rem(8);
	height: rem(8);
	fill: var(--gray-40);
	right: rem(16);
	top: 50%;
	margin-top: -rem(4);
}

.c-products-filter__label {
	// padding: rem(22) 0 rem(22) rem(24);
	padding: var(--space-3) 0 var(--space-3) var(--space-6);
	font-size: var(--font-size-3);
	line-height: rem(16);
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 rem(20) 0 0;

	@include breakpoint(min-768) {
		border-bottom: rem(1) solid var(--gray-20);
		padding: rem(12) rem(16) rem(12) rem(24);
		font-size: var(--font-size-3);
		line-height: rem(16);
		position: relative;
		margin: 0 rem(20) 0 rem(16);
	}

	@include breakpoint(min-1024) {
		border-bottom: none;
		padding: rem(12) 0 rem(12) rem(24);
	}
}

.c-products-filter__label .icon {
	fill: var(--gray-60);
	width: rem(24);
	height: rem(24);
	display: block;
	margin-right: rem(8);
}

.c-products-filter__label picture {
	width: rem(24);
	height: rem(24);
	display: block;
	margin-right: rem(8);
}

.c-products-filter__label .icon-circle {
	border-radius: 50%;
}

.c-products-filter__label .icon-barva_kovu-195 {
	background: linear-gradient(225deg, #b69563 -1.25%, #f1eadc 98.75%);
}
.c-products-filter__label .icon-barva_kovu-196,
.c-products-filter__label .icon-barva_kovu-203 {
	background: linear-gradient(225deg, #acacac -1.25%, #f4f4f4 98.75%);
}
.c-products-filter__label .icon-barva_kovu-197 {
	background: linear-gradient(225deg, #b58666 -1.25%, #eee2d7 98.75%);
}

.c-products-filter__label .icon-barva_hlavniho_kamene-382 {
	background-color: var(--sc-white, #fff);
	box-shadow: inset 0 0 0 rem(2) var(--gray-30);
}
.c-products-filter__label .icon-barva_hlavniho_kamene-387 {
	background-color: var(--sc-pink, #ff94b0);
}
.c-products-filter__label .icon-barva_hlavniho_kamene-391  {
	background-color: var(--sc-red, #e35185);
}
.c-products-filter__label .icon-barva_hlavniho_kamene-388 {
	background-color: var(--sc-ruby-red, #be1e2d);
}
.c-products-filter__label .icon-barva_hlavniho_kamene-384 {
	background-color: var(--sc-purple, #7f519b);
}
.c-products-filter__label .icon-barva_hlavniho_kamene-389 {
	background-color: var(--sc-dark-blue, #3636a1);
}
.c-products-filter__label .icon-barva_hlavniho_kamene-390 {
	background-color: var(--sc-light-blue, #8896ff);
}
.c-products-filter__label .icon-barva_hlavniho_kamene-392 {
	background-color: var(--sc-mint-green, #4fffd7);
}
.c-products-filter__label .icon-barva_hlavniho_kamene-385 {
	background-color: var(--sc-smaragd-green, #00b364);
}
.c-products-filter__label .icon-barva_hlavniho_kamene-401 {
	background-color: var(--sc-olive-green, #21e05e);
}
.c-products-filter__label .icon-barva_hlavniho_kamene-400 {
	background-color: var(--sc-yellow, #fff363);
}
.c-products-filter__label .icon-barva_hlavniho_kamene-386 {
	background-color: var(--sc-orange, #ff8700);
}

.c-products-filter__item-text {
	line-height: rem(24);
	display: block;
}

.c-products-filter__item-link {
	text-decoration: none;
	color: var(--gray-80);
}

.c-products-filter__item-extra-info {
	line-height: rem(16);
	font-size: var(--font-size-3);
	display: block;
	flex: 0 0 100%;
	color: var(--gray-70);
	font-weight: var(--font-weight-regular);
}

.c-products-filter__label input {
	margin-right: rem(8);
}

.c-products-filter__how-to {
	font-size: var(--font-size-2);
	line-height: rem(16);
	padding: rem(12) rem(20) rem(20);
	display: block;
}

.c-products-filter__dropdown {
	margin: 0;
	padding: 0;
	list-style-type: none;
	width: 100%;
	background: var(--white);

	@include breakpoint(min-640) {
	}
}

.c-products-filter__dropdown-item {
	border-bottom: rem(1) solid var(--gray-20);
	padding: rem(12) rem(16);
	font-size: var(--font-size-3);
	line-height: rem(16);
	position: relative;
}

.c-products-filter__dropdown-item:last-child {
	border-bottom: none;
	border-radius: 0 0 rem(3) rem(3);
}

.c-products-filter__dropdown-item:hover {
	background-color: var(--gray-10);
}

.c-products-filter__dropdown-item--disabled {
	text-decoration: line-through;
	cursor: not-allowed;
	color: var(--gray-50);
}

.c-products-filter__dropdown-item a {
	display: block;
	font-size: var(--font-size-3);
	line-height: rem(16);
	text-decoration: none;
	color: var(--gray-80);
}

.c-products-filter__dropdown-item a:hover {
	font-weight: var(--font-weight-semibold);
}

.c-products-filter__dropdown-item a::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.c-products-filter__checkboxes-container {
	display: block;
	background-color: var(--white);

	@include breakpoint(min-768) {
		display: none;
		position: absolute;
		right: 0;
		z-index: $z-index-5;
		width: auto;
		min-width: rem(200);
		top: 100%;
		box-shadow: 0 0 rem(20) 0 rgba(0, 0, 0, 0.2);
		border-radius: 0 0 rem(3) rem(3);
	}

	@include breakpoint(min-1024) {
		display: block;
		position: static;
		box-shadow: none;
	}
}

.c-products-filter__checkboxes-container--visible {
	display: block;
}

.c-products-filter__list--sort {
	margin-left: auto;
}

.c-products-filter__checkboxes-toggle {
	display: none;
	position: relative;
	text-transform: uppercase;
	color: var(--gray-80);
	font-size: var(--font-size-2);
	line-height: rem(20);
	padding: rem(20) rem(32) rem(20) rem(20);
	border: none;
	text-align: left;
	border-bottom: rem(1) solid var(--gray-20);
	font-weight: var(--font-weight-semibold);

	@include breakpoint(min-768) {
		display: block;
		margin-left: auto;
		border-bottom: none;
	}

	@include breakpoint(min-1024) {
		display: none;
	}
}

.c-products-filter__checkboxes-toggle .icon-chevron-bottom {
	position: absolute;
	top: 50%;
	position: absolute;
	width: rem(8);
	height: rem(8);
	fill: var(--gray-40);
	right: rem(16);
	margin-top: -rem(4);
}

.c-products-filter__filter-toggle {
	position: relative;
	text-transform: uppercase;
	color: var(--gray-80);
	font-size: var(--font-size-2);
	line-height: rem(20);
	padding: rem(20) rem(32) rem(20) rem(44);
	border: none;
	width: 100%;
	text-align: left;
	font-weight: var(--font-weight-semibold);
	background-color: var(--white);

	@include breakpoint(min-1024) {
		display: none;
	}
}

.c-products-filter__filter-toggle .icon {
	position: absolute;
	top: 50%;
}

.c-products-filter__filter-toggle .icon-filter {
	transform: rotate(-90deg);
	height: rem(16);
	width: rem(16);
	left: rem(16);
	margin-top: -rem(8);
}

.c-products-filter__filter-toggle .icon-chevron-bottom {
	position: absolute;
	width: rem(8);
	height: rem(8);
	fill: var(--gray-40);
	right: rem(16);
	margin-top: -rem(4);
}
