/* ===========================
Component - Category Signpost
=========================== */

.c-category-subnav {
}

.c-category-subnav__list {
	display: flex;
	list-style-type: none;
	padding: var(--space-3) 4%;
	scroll-snap-type: x mandatory;
	overflow-x: scroll;
	scroll-behavior: smooth;

	@include breakpoint(min-960) {
		padding: var(--space-4) 4%;
	}
}

.c-category-subnav__item {
	flex: 0 0 rem(140);
	margin: var(--space-1);

	@include breakpoint(min-960) {
		flex: 0 0 rem(160);
	}
}

.c-category-subnav__item a {
	display: block;
	text-decoration: none;
}

.c-category-subnav__item a:hover {
	text-decoration: underline;
}

.c-category-subnav__item a:focus {
	outline: none;
	background-color: transparent;
	text-decoration: underline;
}

.c-category-subnav__item:first-child {
	margin-left: auto;
}

.c-category-subnav__item:last-child {
	margin-right: auto;
}

.c-category-subnav__item-image {
	background-color: var(--white);
	display: block;
	margin-bottom: var(--space-1);

	@include breakpoint(min-960) {
		margin-bottom: var(--space-2);
	}
}

.c-category-subnav__item a:focus .c-category-subnav__item-image {
	box-shadow: 0 0 0 var(--space-05) #7c999a;
}

.c-category-subnav__item a:hover .c-category-subnav__item-image {
	box-shadow: 0 0 rem(20) 0 rgba(var(--taupe-400), 0.3);
}

.c-category-subnav__item-image img {
	display: block;
	max-width: 100%;
	height: auto;
}

.c-category-subnav__item-name {
	font-size: var(--font-size-2);
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	-webkit-box-orient: vertical;
	color: rgba(var(--taupe-900), 1);

	@include breakpoint(min-960) {
		font-size: var(--font-size-3);
	}
}
