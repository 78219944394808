.c-filter-results {
	background-color: var(--gray-10);
	border-radius: rem(6);
	display: flex;
	padding: rem(16);
	margin-bottom: rem(20);
	align-items: flex-start;
}

.c-filter-results__items-count {
	font-size: var(--font-size-3);
	line-height: rem(32);
	font-weight: var(--font-weight-semibold);
	color: var(--gray-90);
	margin-right: rem(20);
	flex: 0 0 auto;
}

.c-filter-results__list {
	list-style-type: none;
	margin: rem(-4);
	padding: 0;
	display: flex;
	flex-wrap: wrap;
}

.c-filter-results__list-item {
	margin: rem(4);
	display: none;

	@include breakpoint(min-640) {
		display: block;
	}
}

.c-filter-results__list-item a {
	position: relative;
	background-color: var(--white);
	font-size: var(--font-size-3);
	line-height: rem(16);
	font-weight: var(--font-weight-semibold);
	text-decoration: none;
	color: var(--gray-70);
	padding: rem(8) rem(36) rem(8) rem(12);
	border-radius: rem(32);
	display: block;
}

.c-filter-results__list-item a:hover {
	background-color: var(--gray-20);
	text-decoration: underline;
}

.c-filter-results__list-item .icon {
	position: absolute;
	width: rem(8);
	height: rem(8);
	top: rem(12);
	right: rem(12);
	fill: var(--gray-70);
}

.c-filter-results__list-item--cancel {
	display: block;
}

.c-filter-results__list-item--cancel a {
	background: transparent;
	border-radius: 0;
	padding: rem(8);
	color: var(--gray-90);
}

.c-filter-results__list-item--cancel a:hover {
	background-color: transparent;
}

.c-filter-results__sort-option {
	position: relative;
	margin-left: auto;
	flex: 0 0 auto;
}

.c-filter-results__sort-desc {
	font-size: var(--font-size-3);
	color: var(--gray-70);
	line-height: rem(16);
	display: none;

	@include breakpoint(min-640){
		display: inline;
	}
}

.c-filter-results__sort-button {
	position: relative;
	padding: rem(8) rem(20) rem(8) rem(8);
	color: var(--gray-90);
	font-size: var(--font-size-3);
	line-height: rem(16);
	border-radius: rem(3);
	font-weight: var(--font-weight-semibold);
	text-decoration: underline;
	border: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: transparent;
}

.c-filter-results__sort-button:hover {
	background-color: var(--white);
}

.c-filter-results__sort-button .icon {
	position: absolute;
	width: rem(8);
	height: rem(8);
	top: 50%;
	right: rem(4);
	fill: var(--gray-50);
	transform: translateY(-50%);
}

.c-filter-results__sort-list {
	position: absolute;
	z-index: $z-index-5;
	background: var(--white);
	list-style-type: none;
	margin: 0;
	padding: 0;
	right: 0;
	border-radius: rem(4);
	box-shadow: 0 0 rem(20) 0 rgba(0,0,0,0.2);  
	min-width: rem(200);
}

.c-filter-results__sort-list-item {
	border-bottom: rem(1) solid var(--gray-20);
	font-size: var(--font-size-3);
	line-height: rem(16);
	position: relative;
}

.c-filter-results__sort-list-item a {
	display: block;
	text-decoration: none;
	padding: rem(12) rem(16);
	font-weight: var(--font-weight-semibold);
	color: var(--gray-90);
}

.c-filter-results__sort-list-item a:hover {
	background-color: var(--gray-10);
}